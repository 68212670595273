import { ProGallery } from 'pro-gallery';
import 'pro-gallery/dist/statics/main.css';

function Gallery() {

    // Add your images here...
    const items = [
        { // Image item:
            itemId: 'CatTikTok',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/tiktok_cat_filter.png?raw=true',
            metaData: {
                type: 'image',
                title: 'CatTikTok',
                description: 'CatTikTok',
                focalPoint: [0, 0],
                link: {
                url: 'https://yoshium.dev/#/cattiktok',
                },
            }
        },
        { // Image item:
            itemId: 'HiLo',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/hilo_2.png?raw=true',
            metaData: {
                type: 'image',
                title: 'HiLo',
                description: 'HiLo',
                focalPoint: [0, 0],
                link: {
                url: 'https://yoshium.dev/#/hilo',
                },
            }
        },
        { // Image item:
            itemId: 'Polyglot',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/polyglot.png?raw=true',
            metaData: {
                type: 'image',
                title: 'Polyglot',
                description: 'Polyglot',
                focalPoint: [0, 0],
                link: {
                url: 'https://yoshium.dev/#/polyglot',
                },
            }
        },
        { // Image item:
            itemId: 'RBC',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/rbc.png?raw=true',
            metaData: {
                type: 'image',
                title: 'RBC',
                description: 'RBC',
                focalPoint: [0, 0],
                link: {
                url: 'https://yoshium.dev/#/rbc',
                },
            }
        },
        { // Image item:
            itemId: 'LISP',
            mediaUrl: 'https://user-images.githubusercontent.com/31518615/99130170-999aef00-25dd-11eb-9321-1da7a5cac71c.png',
            metaData: {
                type: 'image',
                title: 'LISP',
                description: 'LISP',
                focalPoint: [0, 0],
                link: {
                url: 'https://github.com/KeeganMcGinnis2/FallHack2020',
                },
            }
        },
        { // Image item:
            itemId: 'yoshiumdev',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/yoshiumdev_banner.png?raw=true',
            metaData: {
                type: 'image',
                title: 'yoshiumdev',
                description: 'yoshiumdev',
                focalPoint: [0, 0],
                link: {
                url: 'https://yoshium.dev/#/yoshiumdev',
                },
            }
        },
        { // Image item:
            itemId: 'EngBot',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/engbot.png?raw=true',
            metaData: {
                type: 'image',
                title: 'EngBot',
                description: 'EngBot',
                focalPoint: [0, 0],
                link: {
                url: 'https://github.com/yoshiumw/engbot',
                },
            }
        },
        // { // Image item:
        //     itemId: 'StealSeekers',
        //     mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/stealseekers%20logo.png?raw=true',
        //     metaData: {
        //         type: 'image',
        //         title: 'StealSeekers',
        //         description: 'StealSeekers',
        //         focalPoint: [0, 0],
        //         link: {
        //         url: 'https://github.com/yoshiumw/Wishlist',
        //         },
        //     }
        // },
        { // Image item:
            itemId: 'ChargingUp',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/bigchargingupbanner.png?raw=true',
            metaData: {
                type: 'image',
                title: 'ChargingUp',
                description: 'ChargingUp',
                focalPoint: [0, 0],
                link: {
                url: 'https://www.youtube.com/watch?v=wghuUpWEi4A',
                },
            }
        },
        { // Image item:
            itemId: 'UPass',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/upass.png?raw=true',
            metaData: {
                type: 'image',
                title: 'UPass',
                description: 'UPass',
                focalPoint: [0, 0],
                link: {
                url: 'https://github.com/yoshiumw/upass',
                },
            }
        },
        { // Image item:
            itemId: '3D_Tic_Tac_Toe',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/3dttt.png?raw=true',
            metaData: {
                type: 'image',
                title: '3D_Tic_Tac_Toe',
                description: '3D_Tic_Tac_Toe',
                focalPoint: [0, 0],
                link: {
                url: 'https://github.com/yoshiumw/3dtictactoe',
                },
            }
        },
        { // Image item:
            itemId: 'PearingUp',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/pearingup%20logo.png?raw=true',
            metaData: {
                type: 'image',
                title: 'PearingUp',
                description: 'PearingUp',
                focalPoint: [0, 0],
                link: {
                url: 'https://www.youtube.com/watch?v=2S0B0i7NLzM',
                },
            }
        },
        { // Image item:
            itemId: 'HTMLColourPuzzle',
            mediaUrl: 'https://github.com/yoshiumw/random_assets/blob/main/colourpuzzle.png?raw=true',
            metaData: {
                type: 'image',
                title: 'HTMLColourPuzzle',
                description: 'HTMLColourPuzzle',
                focalPoint: [0, 0],
                link: {
                url: 'https://github.com/yoshiumw/ColouringPuzzle',
                },
            }
        },
        
    ];

    // The options of the gallery (from the playground current state)
    const options = {
        "layoutParams": {
            "structure": {
                "galleryLayout": 0,
                "layoutOrientation": false
            },
            "groups": {
                "density": "0.9"
            },
            "targetItemSize": {
                "value": 100,
                "minimum": 1000
            }
        },
        "behaviourParams": {
            "item": {
                "content": {
                    "hoverAnimation": "ZOOM_IN"
                }
            }
        }
    };

    // The size of the gallery container. The images will fit themselves in it
    const container = {
        width: window.innerWidth,
        height: window.innerHeight
    };

    // The eventsListener will notify you anytime something has happened in the gallery.
    const eventsListener = (eventName, eventData) => {
        if(eventName === "ITEM_CLICKED"){
            window.scrollTo({ top: 0, behavior: 'smooth' });
            window.open(eventData.linkUrl, '_top'); 
        }
    }
    // The scrollingElement is usually the window, if you are scrolling inside another element, suplly it here
    const scrollingElement = window;

    return (
        <ProGallery
            items={items}
            options={options}
            container={container}
            eventsListener={eventsListener}
            scrollingElement={scrollingElement}
        />
    );
}

export default Gallery;
