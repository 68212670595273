import React, { Component } from 'react'
import Navigation from '../Navigation/Navigation_base'
import './Page.css';

import { ReactComponent as Goback } from './arrow.svg';
import ApolloTree from './Pictures/CatTikTok/apollo_on_tree.png';
import Assets from './Pictures/CatTikTok/assets.png';
import CatFaceSticker from './Pictures/CatTikTok/cat_face_sticker.png';
import CloudWig from './Pictures/CatTikTok/cloud_wig.png';
import ClownApollo from './Pictures/CatTikTok/clown_apollo.png';
import FuzzyWig from './Pictures/CatTikTok/fuzzy_wig.png';
import NoTexture from './Pictures/CatTikTok/no_texture.png';
import NodeMap from './Pictures/CatTikTok/node_map.png';
import Result from './Pictures/CatTikTok/result.png';
import SphereLink from './Pictures/CatTikTok/sphere_link.png';
import WigWithTexture from './Pictures/CatTikTok/wig_with_texture.png';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import {animateScroll as scroll} from 'react-scroll'

function CatTikTok(){
    return (
        <div>
            <Navigation/>
            <div style={{backgroundColor:"#1A1A1D"}} id="pageContainer">
                <Container id = "bodyContainer">
                    <Row>
                        <Goback className="logo" onClick={() => {window.open("https://yoshium.dev/", '_top'); }} />
                    </Row>
                    <Row>
                        <h1 className="yellow">How I Made a TikTok Filter for my Cat</h1>
                    </Row>
                    <Row>
                        <h5>(Nov 2022)</h5>
                    </Row>
                    <Row>
                        <p>
                            I recently got a new furry friend, Apollo, to be my companion for my time in Calgary working as a Full Stack Developer for RBC (again!).
                        </p>
                        <Image src={ApolloTree} fluid />
                        <p>
                            I wanted to document my time with Apollo, so I created a TikTok for him. On TikTok, I noticed a lack of cat filters and got inspired by another creator on TikTok (@devseb). As a technology person myself, I decided to learn 3D modelling in Blender and the tool created by TikTok to create filters, Effect House. I initially started 2D by just using stickers in Effect House, but it looked a bit weird when Apollo turned his head which is the why I wanted to make some of the elements of my filter 3D by using Blender models. Apollo is a very silly kitten, so I decided to make him a clown filter. 
                        </p>

                        <Image src={ClownApollo} fluid />
                    </Row>
                    <Row style={{marginTop:"5vh"}} >
                        <h5 className="yellow">Working With Blender</h5>
                    </Row>
                    <Row>
                        <p>
                            As a noob to 3D modelling, I followed a tutorial to make a donut by @Blender Guru on YouTube. His series is a great starting point and allowed me to learn the finnicky camera movements and tools when working in a 3D environment. For the filter I created, I wanted to make the clown wig 3D. Initially I tried making a hole in the clown wig for the head placement but that would later haunt me as the texture map that I created for that model did not work in Effect House. Instead, I just have a sculpted UV sphere that has many bumps that resemble that of a clown wig. I also learned that you cannot export particles in an FBX file so I could not export fuzzy hair.
                        </p>
                        <Image src={WigWithTexture} fluid />
                        <p>
                            I made a simple node map to add colour to my clown wig. I used a ColorRamp and connected it to my Principled BSDF node to do the gradient of the rainbow for the wig. To export a texture map, you must first create a UV map and then you are able to bake the colors onto that mapping and export it as an image file you can use later as a texture in Effect House. (More info in this video I followed: https://youtu.be/x4mySebugl0)
                        </p>
                        <Image src={NodeMap} fluid />
                        <p>
                            To export everything, export the model as an FBX and choose Selected Models and export the textures as a PNG separately. 
                        </p>
                        
                    </Row>
                    <Row style={{marginTop:"5vh"}} >
                        <h5 className="yellow">How to Use TikTok's Effect House</h5>
                    </Row>
                    <Row>
                        <p>
                            To start, I imported all my assets I created in Blender (the model + the texture) and more (some images for the clown nose and blush). 
                        </p>
                        <Image src={Assets} fluid />
                        <p>
                            First, I created an occluder and set it to “cat” for the wig to sit on. This is done by adding “AR Tracking, Head Tracker. Then I scaled and dragged the model of the clown wig into the Head Tracker. Initially it will just be a white blob because I haven’t added texture to it yet. 
                        </p>
                        <Image src={NoTexture} fluid/>
                        <p>
                            To add texture to this blob, click on the material the “Sphere” model is linked to. You can find this information by clicking on Sphere and looking at the bar on the right-hand side.
                        </p>
                        <Image src={SphereLink} fluid/>
                        <p>
                            Find the material file in your assets and click on it, the properties of the material should appear on the right-hand side. In my case, I just clicked texture on for the “Albedo” and added my texture I exported from Blender. And voila, the texture should be applied onto the wig of the cat model.
                        </p>
                        <Image src={WigWithTexture} fluid/>
                        <p>
                            Next I added a few “Pet Face Effects” specifically the “Cat Face Sticker.” For this, add your desired image as texture and resize to the desired size on the cat face. Drag it onto the the different blue dots so it tracks better.
                        </p>
                        <Image src={CatFaceSticker}/>

                        
                    </Row>
                    <Row style={{marginTop:"5vh"}} >
                        <h5 className="yellow">Conclusion</h5>
                    </Row>
                    <Row>
                        <p>
                            This was a fun weekend day project. I’m glad I got to explore how 3D modelling and how those filters that I have been using since high school work. Maybe I will do more in the future, but I need to come up with cool ideas first!
                        </p>
                        <Image src={Result} fluid/>
                    </Row>
                    <Row style={{marginTop:"5vh"}} >
                        <a className="top" onClick={() => {scroll.scrollToTop()}}>Go back to top.</a>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default CatTikTok;