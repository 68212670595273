
import './App.css';
import React, { useState, useEffect } from 'react';
import ParticlesBg from 'particles-bg'

import Gallery from '../Gallery/Gallery'
import Navigation from '../Navigation/Navigation'
import About from '../About/About'

function App() {
  const roles = ['a Graduate', 'a Student', 'a Developer', 'a Designer', 'an Adventurer', 'a Gamer', 'a Learner', 'a Tester', 'a Creator', 'an Innovator', 'a Collaborator'];
  const [role, setRole] = useState('a Student');
  var count = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      setRole(role => roles[count]);
      count = count + 1;
      if(count > roles.length-1){
        count = 0;
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{backgroundColor:"#1A1A1D"}} className="App">
      <Navigation/>
      <div className="intro">
        <h1 style={{color:"#FEE715FF", fontWeight:600}} id="hello">Hey!</h1>
        <h2 className="introText" style={{color:"white"}}>My name is Yo Shium Wong,</h2>
        <h3 style={{fontWeight:300}} className="introText">I am <br/> <span style={{fontWeight:400}}>{role}.</span> </h3>
      </div>
      <div id="particlesBG">
        <ParticlesBg style={{width:"150%"}} color={["#FFFFFF"]} num={25} type="cobweb" bg={false}/>
      </div>
      <Gallery id="gallery"/>
      <About/>
    </div>
  );
}

export default App;
