import React, { Component } from 'react'
import Navigation from '../Navigation/Navigation_base'
import './Page.css';

import { ReactComponent as Goback } from './arrow.svg';
import header from './Pictures/dev_banner.png';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import {animateScroll as scroll} from 'react-scroll'

function Page(){
    return (
        <div>
            <Navigation/>
            <div style={{backgroundColor:"#1A1A1D"}} id="pageContainer">
                <Container id = "bodyContainer">
                    <Row>
                        <Goback className="logo" onClick={() => {window.open("https://yoshium.dev/", '_top'); }} />
                    </Row>
                    <Row>
                        <h1 className="yellow">yoshium.dev - My Portfolio Site</h1>
                    </Row>
                    <Row>
                        <h5>(Oct 2020 - Present)</h5>
                        <Image src={header} style={{marginTop:"5vh", marginBottom:"5vh"}} fluid />
                    </Row>
                    <Row>
                        <h4 className="yellow subtitle">Introduction</h4>
                        <p>This is my portfolio website. I previously hosted my website on yoshium.me which was created using the Adobe Design Suite for an IAT course, but I have decided to do this site with React and GitHub pages this time. In this article, I hope to log some cool things I discover about React and the problems I run into with their respective solutions.</p>
                    </Row>
                    <Row>
                        <p>This is a very good resource for deploying a React App to GitHub Pages that I used for this site. (https://github.com/gitname/react-gh-pages)</p>
                    </Row>
                    <Row>
                        <a className="top" onClick={() => {scroll.scrollToTop()}}>Go back to top.</a>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Page;