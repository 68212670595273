import React, { Component } from 'react'
import Navigation from '../Navigation/Navigation_base'
import '../Pages/Page.css';

import { ReactComponent as Goback } from '../Pages/arrow.svg';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import {animateScroll as scroll} from 'react-scroll'
import PhotoViewer from './PhotoViewer';

const photos = [
  'https://github.com/yoshiumw/random_assets/blob/main/photography_work/DSCF8919.JPG?raw=true',
  'https://github.com/yoshiumw/random_assets/blob/main/photography_work/DSCF8930.JPG?raw=true',
  'https://github.com/yoshiumw/random_assets/blob/main/photography_work/DSCF8945.JPG?raw=true',
  'https://github.com/yoshiumw/random_assets/blob/main/photography_work/IMG_0281.jpg?raw=true',
  'https://github.com/yoshiumw/random_assets/blob/main/photography_work/IMG_0287.jpg?raw=true',
  'https://github.com/yoshiumw/random_assets/blob/main/photography_work/IMG_0341.jpg?raw=true',
  'https://github.com/yoshiumw/random_assets/blob/main/photography_work/IMG_0387.jpg?raw=true',
];

function Photos(){
    return (
        <div>
            <Navigation/>
            <div style={{backgroundColor:"#1A1A1D"}} id="pageContainer">
                <Container id = "bodyContainer">
                    <Row>
                        <Goback className="logo" onClick={() => {window.open("https://yoshium.dev/", '_top'); }} />
                    </Row>
                    <Row>
                        <h1 className="yellow">Photography</h1>
                    </Row>
                    <Row style={{marginTop:"10vh", marginBottom:"20vh"}}>
                        <PhotoViewer photos={photos} />
                    </Row>
                    <Row>
                        This page was built purely using ChatGPT. Very cool!
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Photos;