import React, { Component } from 'react'
import './About.css';
import { ReactComponent as Github } from './github.svg';
import { ReactComponent as LinkedIn } from './linkedin.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import MyPDF from './general_resume.pdf';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function About(){
    return(
        <div id="aboutContainer">
            <Container style={{paddingTop:"10vh"}}>
                <Row>
                    <h1 className="pad yellow">About</h1>
                </Row>
                <Row>
                    <Col className="pad">
                        <h2 style={{fontWeight:300}}>
                            I am a Graduate with a BSc. in Computing Sciences from Simon Fraser University in Vancouver, British Columbia 🍁. I am currently a Full Stack Developer @ RBC.
                        </h2>
                    </Col>
                </Row>
                <Row style={{marginTop:"3vh"}}>
                    <Col className="pad">
                        <h5 className="yellow">Interests</h5>
                        <ul>
                            <li>Photography / Design</li>
                            <li>League of Legends <br/> (Challenger S3/S4/S5)</li>
                            <li>Teamfight Tactics (Masters S3.5)</li>
                        </ul>  

                    </Col>
                    <Col>
                        <h5 className="yellow">Resume</h5>
                        <p><a href={MyPDF} className="download" download>Download</a></p>
                    </Col>

                </Row>
                <Row className="pad" style={{marginTop:"3vh"}}>
                    <a href="https://github.com/yoshiumw">
                        <Github className="logo"/>
                    </a>
                    <a href="https://www.linkedin.com/in/yoshiumw/">
                        <LinkedIn className="logo" />
                    </a>
                    <a href="https://www.instagram.com/yoshium/">
                        <Instagram className="logo" />
                    </a>
                </Row>
            </Container>
        </div>
    );
}

export default About;