import React, { Component } from 'react'

import Navbar from 'react-bootstrap/Navbar'
import './Navigation.css';

function Navigation(){
    return (
        <Navbar className="black-nav" variant="dark" expand="lg" fixed="top">
            <Navbar.Brand id="brand" style={{fontWeight:700}} onClick={() => {window.open("https://yoshium.dev/", '_top'); }}>YO SHIUM <span style={{color: "#FEE715FF"}}>WONG</span> </Navbar.Brand>
        </Navbar>
    );
}

export default Navigation;