import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './Components/App/App';

// import Page from './Components/Pages/Page';
import RBC from './Components/Pages/RBC';
import Dev from './Components/Pages/Yoshiumdev';
import HiLo from './Components/Pages/HiLo';
import Polyglot from './Components/Pages/Polyglot';
import CatTikTok from './Components/Pages/CatTikTok';
import Photos from './Components/Photos/Photos.js';
import reportWebVitals from './reportWebVitals';
import {Route, HashRouter as Router} from 'react-router-dom'

ReactDOM.render(
  <React.StrictMode>
    <Router basename='/'>

      {/* <App /> */}
      <Route exact path='/'  component={App}/>
      <Route path='/rbc' component={RBC}/>
      <Route path='/yoshiumdev' component={Dev}/>
      <Route path='/hilo' component={HiLo}/>
      <Route path='/polyglot' component={Polyglot}/>
      <Route path='/cattiktok' component={CatTikTok}/>
      <Route path='/photos' component={Photos}/>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
