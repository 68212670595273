import React, { Component } from 'react'
import Navigation from '../Navigation/Navigation_base'
import './Page.css';

import { ReactComponent as Goback } from './arrow.svg';
import header from './Pictures/WPP.jpg';
import aus from './Pictures/AUS.jpg';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import {animateScroll as scroll} from 'react-scroll'

function RBC(){
    return (
        <div>
            <Navigation/>
            <div style={{backgroundColor:"#1A1A1D"}} id="pageContainer">
                <Container id = "bodyContainer">
                    <Row>
                        <Goback className="logo" onClick={() => {window.open("https://yoshium.dev/", '_top'); }} />
                    </Row>
                    <Row>
                        <h1 className="yellow">My First Co-op as an RBC Developer</h1>
                    </Row>
                    <Row>
                        <h5>(Sep 2019 - Aug 2020)</h5>
                        <Image src={header} style={{marginTop:"5vh", marginBottom:"5vh"}} fluid />
                    </Row>
                    <Row>
                        <p>
                            When I first entered university, one of my goals was to land a development co-op or internship at a company I recognized or have interacted with before. At the start of the Spring 2019 semester, I applied to upwards of 30 jobs before receiving interviews with RBC and a few start-ups. I was extremely nervous for these interviews as I have never worked in a technical position before. I vividly remember griding out LeetCode questions the days leading up to the interview. 
                        </p>
                        <p style={{fontStyle:"italic"}}>
                            Are they going to test me on hard questions? Which algorithms am I expected to know? Is efficiency important?
                        </p>
                        <p>
                            Questions and worries clouded my mind and began to eat away at my sleep schedule. Those worries translated to a slightly trembling voice on interview day. I remember it being an early morning as I was in Vancouver being interviewed remotely from Toronto. I was interviewed by my future manager, Carlos, and Paul, one of the senior developers on the team. The interview was a blur and the next thing I remember was thanking them for their time. I am glad it was not a video call because I was sure I was shaking from adrenaline. To be honest, this was one of the interviews that I was not sure if I was going to get an offer which made receiving the acceptance e-mail even sweeter. I remember ecstatically jumping up and down and immediately running downstairs to tell my mom the news. The hardest part about accepting the offer was deciding if I really wanted to move across the country for an entire year. I ultimately accepted (which is why I am writing this post) but I sat on the decision for a few days.
                        </p>
                        <p>
                            I remember my first day walking into 88 Waterpark Place and taking the elevator to the 6th floor. This was right after a department-wide orientation for co-ops, so I had already gotten the chance to meet a few people. I remember waiting in the elevator waiting area for Carlos to come retrieve me as I had not gotten a door fob yet. “Yoshi!” Carlos hollered as he walked over and shook my hand. I was not used to being called “Yoshi”, but I was glad my manager was such a fun and positive person. I remember walking about the floor and meeting all the amazing people I would be working with for the next 12 months.
                        </p>
                        <h4 className="yellow subtitle">First Semester (FA2019)</h4>
                        <p>
                            The first week was mostly on-boarding and setting up my workstation. The second week was learning the codebase for the project I was going to be working on. The project I was working had lots of learning opportunities because it included many technologies I had never or barely worked with before. These included: Pandas, Django, and some document processing libraries. This was a cool project to work on because I was given many chances to also develop my soft skills especially requirement gathering and presenting. My manager was amazing at giving presentations, so I learned a whole lot from just watching him.
                        </p>
                        <p>
                            I also got to attend many events hosted by the T&amp;O Student Program. These included workshops, networking events, socials (&lt;3 ice skating @ Nathan Phillips Square), and community events. A “community” is a group of students that work in the same office building / floor that meet up on a regular basis to network and socialize. One of the bigger events that the student program hosts is the RBC Innovation Challenge, a hackathon for co-ops. This hackathon can range from a week to a few months. In the case of Fall 2019, it was a 6 week-er and I got to participate with some of my fellow community members. It was an interesting experience because I have never participated in a hackathon where my team was not all developers. It was cool getting some insight from business students and seeing the designs from an experienced UI/UX designer. In the end, we did not win anything, but it was a good learning experience and I got to meet lots of interesting people.
                        </p>
                        <p>
                            My favourite moment from this first semester was watching my manager battle against one of the other co-ops on my team in Chess. 
                        </p>
                        <h4 className="yellow subtitle">Second Semester (SP2020)</h4>
                        <p>
                            There was a quiet period at the start of the semester where nobody was in the office because of Christmas vacation. I remember on the day of Christmas Eve the only people on my team working was me, my manager, and 2 other coworkers. I remember going up to the top floor of WPP and working on my next project while looking out at the amazing view. Everything felt so small in comparison to the height we were working at. Anways, I was learning React for the next project I was supposed to be working on which was a redesign for the main product of our team. I was also trying to figure out how to get Django REST API to work with React on that day. This is the main inspiration of why yoshium.dev is created using React. 
                        </p>
                        <p>
                            This semester I became a community leader and hosted a slew of events for my new community. My job was to get my community accustomed to the corporate environment of RBC and to give a helping hand if they ever get lost in their first semester at RBC. I also participated in the Innovation Challenge once again this semester and was placed in a team where I was the nonbusiness student. This resulted in not a very good time as I had to juggle between the jobs of being the only Developer and the only UI / UX designer. This hackathon really challenged me and I think I came out learning a lot more UX principles as a result. Ultimately, we won the 3 (Prototype)-Screens reward!
                        </p>
                        <p>
                            The WFH orders also went out near the end of this semester. Initially, I was pretty hyped to be able to work from the comfort of my apartment but as the weeks went by without seeing my team, I got a little bit sad.
                        </p>
                        <h4 className="yellow subtitle">Third Semester (SU2019)</h4>
                        <p>
                            This was honestly a pretty depressing time for me. I didn’t get to see my team, the gym was closed, and I was living alone in a 400sqft apartment. The only people I saw during this time were food delivery people and the grocery store cashiers. I had to learn to cope with working in a WFH environment. This included being physically active with body weight fitness, taking regular breaks (cooking, drinking coffee), and meeting new people (also Animal Crossing villagers) virtually over video calls. This time at home was definitely worse than being in the office but I learned how to be productive WFH.
                        </p>
                        <p>
                            This semester I took on a project where I was the sole correspondent with the business owner of the project. This meant that I had to gather all the requirements and also lead the project. In this project, I learned a lot of data analytical skills specifically using the ELK (Elastic, LogStash, Kibana) stack. It was interesting learning how to do data preprocessing and ingest data for the different metrics we were trying to look at.
                        </p>
                        <p>
                            This semester, instead of being a participant in the Innovation Challenge, I was given the opportunity to be a mentor. This position entailed assisting the participants with any questions they might have and also judging the entries. It was a rewarding experience and I learned a lot that goes behind the scenes in hackathons.
                        </p>
                        <h4 className="yellow subtitle">Overall</h4>
                        <Image src={aus} style={{marginTop:"5vh", marginBottom:"5vh"}} fluid />
                        <p>
                            I am extremely grateful to have been part of such a supportive team where I was allowed to grow both as a person and a developer. Huge shoutouts to my manager, Carlos, director, Biren, and the rest of Automation Solutions for leading me on a path to success during this internship. (Spencer, Paul, Janelle, Daniel, Michael, Navpreet, Christopher, Adam, Juliaan)
                        </p>
                        <h3 className="yellow subtitle">Thank You.</h3>
                    </Row>
                    <Row>
                        <a className="top" onClick={() => {scroll.scrollToTop()}}>Go back to top.</a>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default RBC;