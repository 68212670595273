import React, { useState, useEffect, useRef } from 'react';
import leftArrow from './left-arrow.svg';
import rightArrow from './right-arrow.svg';

import "./PhotoViewer.css"

function PhotoViewer({ photos }) {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const timeoutRef = useRef();

  const handlePrevClick = () => {
    setCurrentPhotoIndex(currentPhotoIndex === 0 ? photos.length - 1 : currentPhotoIndex - 1);
  };

  const handleNextClick = () => {
    setCurrentPhotoIndex(currentPhotoIndex === photos.length - 1 ? 0 : currentPhotoIndex + 1);
  };

  const startTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      handleNextClick();
    }, 15000);
  };

  const stopTimeout = () => {
    clearTimeout(timeoutRef.current);
  };

  useEffect(() => {
    startTimeout();
    return () => stopTimeout();
  }, [currentPhotoIndex]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button
        onClick={() => {
          stopTimeout();
          handlePrevClick();
          startTimeout();
        }}
        class="transparency"
        style={{ flex: 'none', marginRight: '5vw' }}
      >
        <img src={leftArrow} class="filter" alt="Previous" />
      </button>
      <img
        src={photos[currentPhotoIndex]}
        alt="Current photo"
        style={{ flex: '1', maxWidth: '50%', maxHeight: '100%', marginRight: '8px', objectFit: 'cover'}}
      />
      <button
        onClick={() => {
          stopTimeout();
          handleNextClick();
          startTimeout();
        }}
        class="transparency"
        style={{ flex: 'none', marginLeft: '4.5vw' }}
      >
        <img src={rightArrow} class="filter" alt="Next" />
      </button>
    </div>
  );
}

export default PhotoViewer;
