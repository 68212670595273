import React, { Component } from 'react'
import Navigation from '../Navigation/Navigation_base'
import './Page.css';

import { ReactComponent as Goback } from './arrow.svg';
import architecture from './Pictures/polyglot_architecture.png';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import {animateScroll as scroll} from 'react-scroll'

function Polyglot(){
    return (
        <div>
            <Navigation/>
            <div style={{backgroundColor:"#1A1A1D"}} id="pageContainer">
                <Container id = "bodyContainer">
                    <Row>
                        <Goback className="logo" onClick={() => {window.open("https://yoshium.dev/", '_top'); }} />
                    </Row>
                    <Row>
                        <h1 className="yellow">CMPT383: Polyglot</h1>
                    </Row>
                    <Row>
                        <h5>(May 2021 - Aug 2021)</h5>
                    </Row>
                    <Row>
                        <p>
                            The 'Polyglot' is a project in which my goal was to build a single system that utilizes 3 different languages to do its work. The requirements for this project included using at least one systems language (C, C++, Go, etc.), and at least one scripting language (Bash, JS, Puthon, R, etc.). Along with the languages, I also needed to use two different ways to communicate between the languages.
                        </p>
                        <p>
                            I decided to do a text analysis project in the form of a web application. The application takes in a .txt file from the user and gathers statistics about the body of text submitted. This includes a summary (generated using NLP-based techniques), character count, word count, sentence count, etc. The goal of this project is to minimize the amount of reading needed to get the gist of a body of text (reduces reading by 50%). The methods implemented for this project was adapted from <a href="https://stackabuse.com/text-summarization-with-nltk-in-python/">this article</a> written for NLTK in Python.
                        </p>
                    </Row>
                    <Row style={{marginTop:"5vh"}} >
                        <h5 className="yellow">Languages Used</h5>
                    </Row>
                    <Row>
                        <div>
                            <ul>
                                <li>JavaScript used to handle web based logic and front-end work.</li>
                                <li>Flask (Python) acts as a REST API to process requests sent from the user.</li>
                                <li>Golang is the systems language and does calculations requested by the user.</li>
                            </ul>
                        </div>
                    </Row>
                    <Row style={{marginTop:"5vh"}} >
                        <h5 className="yellow">Communication Methods</h5>
                    </Row>
                    <Row>
                        <div>
                            <ul>
                                <li>JavaScript communicates with Flask (Python) with GET / POST calls.</li>
                                <li>RabbitMQ is used to connect Flask (Python) with Golang.</li>
                            </ul>
                        </div>
                    </Row>
                    <Row>
                        <Image src={architecture} fluid />
                    </Row>
                    <Row style={{marginTop:"5vh"}} >
                        <h5 className="yellow">Features</h5>
                    </Row>
                    <Row>
                        <div>
                            <ul>
                                <li>User can successfully upload their .txt file (other formats do not work).</li>
                                <li>A summary is generated using the text uploaded by the user.</li>
                                <li>Statistics about the text uploaded are created. (character count, word count, sentence count, line count)</li>
                            </ul>
                        </div>
                    </Row>
                    <Row style={{marginTop:"5vh"}} >
                        <a className="top" onClick={() => {scroll.scrollToTop()}}>Go back to top.</a>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default Polyglot;