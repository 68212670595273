import React, { Component } from 'react'
import Navigation from '../Navigation/Navigation_base'
import './Page.css';

import { ReactComponent as Goback } from './arrow.svg';
import architecture from './Pictures/hilo_architecture.png';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import {animateScroll as scroll} from 'react-scroll'
import ReactPlayer from 'react-player'

function HiLo(){
    return (
        <div>
            <Navigation/>
            <div style={{backgroundColor:"#1A1A1D"}} id="pageContainer">
                <Container id = "bodyContainer">
                    <Row>
                        <Goback className="logo" onClick={() => {window.open("https://yoshium.dev/", '_top'); }} />
                    </Row>
                    <Row>
                        <h1 className="yellow">CMPT470: HiLo</h1>
                    </Row>
                    <Row>
                        <h5>(May 2021 - Aug 2021)</h5>
                    </Row>
                    <Row>
                        <p>
                            ‘HiLo’ is a popularity contest game in the form of a web app. Users will be tasked with deciding if thing A is more or less popular than thing B. If they guess correctly they will then compare the popularity of thing B with thing C and this goes on until they are incorrect.
                        </p>
                    </Row>
                    <Row>
                        <ReactPlayer style={{marginBottom:"5vh", marginTop:"5vh"}} url='https://www.youtube.com/watch?v=F0ceOklmhQM' />
                    </Row>
                    <Row>
                        <h5 className="yellow">Features (explained more in the demo video)</h5>
                    </Row>
                    <Row>
                        <div>
                            <ul>
                                <li>Authentication system that allows user login and signup</li>
                                <li>Single Player Gameplay</li>
                                <li>Multiplayer Gameplay</li>
                                <li>Leaderboard System</li>
                                <li>FAQ Page</li>
                            </ul>
                        </div>
                    </Row>
                    <Row>
                        <h5 className="yellow">Architecture (explained more in the demo video)</h5>
                        <Image src={architecture} style={{marginBottom:"5vh"}} fluid />
                    </Row>
                    <Row>
                        <h5 className="yellow">Takeaways</h5>
                        <p>
                            CMPT470 was a fun course to have in my last semester at Simon Fraser University. It is a great stepping stone back into a work environment as I was tasked with collaborating with 3 other peers in the course. It was also a great refresher for working in a web development position. This project allowed me to play around with familiar frameworks like React, and Node.js and also technologies I am not that comfortable with like WebSockets, and deployment. This class taught me a lot about deploying an application. My previous (and limited) experience working with Docker was just mindlessly looking at documentation and creating a DockerFile that I prayed worked but now I understand how to write DockerFiles and docker-compose (I had to write one during my final!) that involve deploying multiple images (frontend, backend, database, messaging queue, etc).
                        </p>
                    </Row>
                    <Row>
                        <a className="top" onClick={() => {scroll.scrollToTop()}}>Go back to top.</a>
                    </Row>
                </Container>
            </div>
        </div>
    );
}

export default HiLo;